// src/link.ts
import { Mark, markPasteRule, mergeAttributes } from "@tiptap/core";
import { find as find3, registerCustomProtocol, reset } from "linkifyjs";

// src/helpers/autolink.ts
import {
  combineTransactionSteps,
  findChildrenInRange,
  getChangedRanges,
  getMarksBetween
} from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { find, test } from "linkifyjs";
function autolink(options) {
  return new Plugin({
    key: new PluginKey("autolink"),
    appendTransaction: (transactions, oldState, newState) => {
      const docChanges = transactions.some((transaction) => transaction.docChanged) && !oldState.doc.eq(newState.doc);
      const preventAutolink = transactions.some((transaction) => transaction.getMeta("preventAutolink"));
      if (!docChanges || preventAutolink) {
        return;
      }
      const { tr } = newState;
      const transform = combineTransactionSteps(oldState.doc, [...transactions]);
      const { mapping } = transform;
      const changes = getChangedRanges(transform);
      changes.forEach(({ oldRange, newRange }) => {
        getMarksBetween(oldRange.from, oldRange.to, oldState.doc).filter((item) => item.mark.type === options.type).forEach((oldMark) => {
          const newFrom = mapping.map(oldMark.from);
          const newTo = mapping.map(oldMark.to);
          const newMarks = getMarksBetween(newFrom, newTo, newState.doc).filter(
            (item) => item.mark.type === options.type
          );
          if (!newMarks.length) {
            return;
          }
          const newMark = newMarks[0];
          const oldLinkText = oldState.doc.textBetween(oldMark.from, oldMark.to, void 0, " ");
          const newLinkText = newState.doc.textBetween(newMark.from, newMark.to, void 0, " ");
          const wasLink = test(oldLinkText);
          const isLink = test(newLinkText);
          if (wasLink && !isLink) {
            tr.removeMark(newMark.from, newMark.to, options.type);
          }
        });
        const nodesInChangedRanges = findChildrenInRange(
          newState.doc,
          newRange,
          (node) => node.isTextblock
        );
        let textBlock;
        let textBeforeWhitespace;
        if (nodesInChangedRanges.length > 1) {
          textBlock = nodesInChangedRanges[0];
          textBeforeWhitespace = newState.doc.textBetween(
            textBlock.pos,
            textBlock.pos + textBlock.node.nodeSize,
            void 0,
            " "
          );
        } else if (nodesInChangedRanges.length && newState.doc.textBetween(newRange.from, newRange.to, " ", " ").endsWith(" ")) {
          textBlock = nodesInChangedRanges[0];
          textBeforeWhitespace = newState.doc.textBetween(
            textBlock.pos,
            newRange.to,
            void 0,
            " "
          );
        }
        if (textBlock && textBeforeWhitespace) {
          const wordsBeforeWhitespace = textBeforeWhitespace.split(" ").filter((s) => s !== "");
          if (wordsBeforeWhitespace.length <= 0) {
            return false;
          }
          const lastWordBeforeSpace = wordsBeforeWhitespace[wordsBeforeWhitespace.length - 1];
          const lastWordAndBlockOffset = textBlock.pos + textBeforeWhitespace.lastIndexOf(lastWordBeforeSpace);
          if (!lastWordBeforeSpace) {
            return false;
          }
          find(lastWordBeforeSpace).filter((link) => link.isLink).filter((link) => {
            if (options.validate) {
              return options.validate(link.value);
            }
            return true;
          }).map((link) => ({
            ...link,
            from: lastWordAndBlockOffset + link.start + 1,
            to: lastWordAndBlockOffset + link.end + 1
          })).forEach((link) => {
            tr.addMark(
              link.from,
              link.to,
              options.type.create({
                href: link.href
              })
            );
          });
        }
      });
      if (!tr.steps.length) {
        return;
      }
      return tr;
    }
  });
}

// src/helpers/clickHandler.ts
import { getAttributes } from "@tiptap/core";
import { Plugin as Plugin2, PluginKey as PluginKey2 } from "@tiptap/pm/state";
function clickHandler(options) {
  return new Plugin2({
    key: new PluginKey2("handleClickLink"),
    props: {
      handleClick: (view, pos, event) => {
        var _a;
        const attrs = getAttributes(view.state, options.type.name);
        const link = (_a = event.target) == null ? void 0 : _a.closest("a");
        if (link && attrs.href) {
          window.open(attrs.href, attrs.target);
          return true;
        }
        return false;
      }
    }
  });
}

// src/helpers/pasteHandler.ts
import { Plugin as Plugin3, PluginKey as PluginKey3 } from "@tiptap/pm/state";
import { find as find2 } from "linkifyjs";
function pasteHandler(options) {
  return new Plugin3({
    key: new PluginKey3("handlePasteLink"),
    props: {
      handlePaste: (view, event, slice) => {
        const { state } = view;
        const { selection } = state;
        const { empty } = selection;
        if (empty) {
          return false;
        }
        let textContent = "";
        slice.content.forEach((node) => {
          textContent += node.textContent;
        });
        const link = find2(textContent).find((item) => item.isLink && item.value === textContent);
        if (!textContent || !link) {
          return false;
        }
        options.editor.commands.setMark(options.type, {
          href: link.href
        });
        return true;
      }
    }
  });
}

// src/link.ts
var Link = Mark.create({
  name: "link",
  priority: 1e3,
  keepOnSplit: false,
  onCreate() {
    this.options.protocols.forEach(registerCustomProtocol);
  },
  onDestroy() {
    reset();
  },
  inclusive() {
    return this.options.autolink;
  },
  addOptions() {
    return {
      openOnClick: true,
      linkOnPaste: true,
      autolink: true,
      protocols: [],
      HTMLAttributes: {
        target: "_blank",
        rel: "noopener noreferrer nofollow",
        class: null
      },
      validate: void 0
    };
  },
  addAttributes() {
    return {
      href: {
        default: null
      },
      target: {
        default: this.options.HTMLAttributes.target
      },
      class: {
        default: this.options.HTMLAttributes.class
      }
    };
  },
  parseHTML() {
    return [{ tag: 'a[href]:not([href *= "javascript:" i])' }];
  },
  renderHTML({ HTMLAttributes }) {
    return ["a", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
  addCommands() {
    return {
      setLink: (attributes) => ({ chain }) => {
        return chain().setMark(this.name, attributes).setMeta("preventAutolink", true).run();
      },
      toggleLink: (attributes) => ({ chain }) => {
        return chain().toggleMark(this.name, attributes, { extendEmptyMarkRange: true }).setMeta("preventAutolink", true).run();
      },
      unsetLink: () => ({ chain }) => {
        return chain().unsetMark(this.name, { extendEmptyMarkRange: true }).setMeta("preventAutolink", true).run();
      }
    };
  },
  addPasteRules() {
    return [
      markPasteRule({
        find: (text) => find3(text).filter((link) => {
          if (this.options.validate) {
            return this.options.validate(link.value);
          }
          return true;
        }).filter((link) => link.isLink).map((link) => ({
          text: link.value,
          index: link.start,
          data: link
        })),
        type: this.type,
        getAttributes: (match) => {
          var _a;
          return {
            href: (_a = match.data) == null ? void 0 : _a.href
          };
        }
      })
    ];
  },
  addProseMirrorPlugins() {
    const plugins = [];
    if (this.options.autolink) {
      plugins.push(
        autolink({
          type: this.type,
          validate: this.options.validate
        })
      );
    }
    if (this.options.openOnClick) {
      plugins.push(
        clickHandler({
          type: this.type
        })
      );
    }
    if (this.options.linkOnPaste) {
      plugins.push(
        pasteHandler({
          editor: this.editor,
          type: this.type
        })
      );
    }
    return plugins;
  }
});

// src/index.ts
var src_default = Link;
export {
  Link,
  src_default as default
};
